import React, { useState } from 'react';
import Flex from 'components/common/Flex';
// import { Form, Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from './AuthProvider';
import LoaderButton from 'si/components/common/LoaderButton';
import './Passcode.css';

const Passcode = () => {
  // State
  const [formData, setFormData] = useState({
    passcode: '',
    remember: false
  });
  const [waiting, setWaiting] = useState(false);

  const navigate = useNavigate();

  const { sendPasscode } = useAuthContext();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();

    // make the button spin
    setWaiting(true);

    // send to api to validate
    const result = await sendPasscode(formData.passcode);

    if (result) navigate('/create');
    else navigate('/auth/failed');
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Passcode login</h5>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {<Form.Label>Step 2 - enter passcode from email</Form.Label>}
          <Form.Control
            placeholder={'Passcode'}
            value={formData.passcode}
            name="passcode"
            onChange={handleFieldChange}
            type="number"
            inputMode="numeric"
          />
        </Form.Group>

        {/* <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Form.Check type="checkbox" id="rememberMe" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember"
                checked={formData.remember}
                onChange={e =>
                  setFormData({
                    ...formData,
                    remember: e.target.checked
                  })
                }
              />
              <Form.Check.Label className="mb-0 text-700">
                Stay logged in
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row> */}

        <Form.Group>
          <LoaderButton
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.passcode || waiting}
            loading={waiting}
          >
            Login with passcode
          </LoaderButton>
        </Form.Group>
      </Form>
    </>
  );
};

export default Passcode;
