import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Modal,
  Row,
  Col,
  CloseButton,
  Spinner
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
// import UserTableHeader from './UserTableHeader';
import IconButton from 'components/common/IconButton';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import WizardInput from 'components/wizard/WizardInput';
import { useForm } from 'react-hook-form';
import Flex from 'components/common/Flex';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useApiContext } from 'si/api/ApiProvider';
import { useAuthContext } from 'si/auth/AuthProvider';

function validEmail(email) {
  return email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
}

const UserTable = () => {
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [key, setKey] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  // const [user, setUser] = useState({});

  const { getUsers, getUser, saveUser, deleteUser } = useApiContext();
  const { jwt, isSuperuser } = useAuthContext();

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
    setFocus
  } = useForm();

  async function fetchUsers() {
    setLoadingList(true);
    const result = await getUsers();
    setLoadingList(false);
    if (result.error) {
      console.log(`error getting users`, result);
    } else {
      console.log(`got users`, result);
      setUserData(result.users);
    }
  }

  async function fetchUser(email) {
    setLoadingOne(true);
    const result = await getUser(email);
    setLoadingOne(false);
    if (result.error) {
      console.log(`error getting user`, result);
      resetModals();
      fetchUsers();
    } else {
      console.log(`got user`, result);
      const user = result.user;
      setValue('email', user.email);
      setValue('name', user.name);
      setValue('admin', user.admin);
      setValue('superuser', user.superuser);
    }
  }

  useEffect(() => {
    setFocus('email');
  }, [show, loadingOne]);

  async function doSave(user) {
    console.log(`Saving ${user.email}`);
    setLoadingSave(true);
    const result = await saveUser(user);
    if (result.error) {
      console.log(`error saving user`, result);
    } else {
      console.log(`saved user`, result);
    }
    setLoadingSave(false);
    return result;
  }

  async function doDelete() {
    // console.log('deleteUser');
    const original = userData.find(u => u.email === key);
    if (original) {
      userData.splice(userData.indexOf(original), 1);
      setLoadingDelete(true);
      resetModals();
      await deleteUser(key);
      setLoadingDelete(false);
    } else {
      resetModals();
    }
  }

  useEffect(() => {
    if (!jwt) return;
    fetchUsers();
  }, [jwt]);

  function resetModals() {
    setShow(false);
    setTimeout(() => {
      setEdit(false);
      setConfirmDelete(false);
    }, 500);
  }

  async function doEdit() {
    const user = getValues();
    const original = userData.find(u => u.email === key);
    const keyChanged = key !== user.email;
    if (original) {
      original.email = user.email.toLowerCase();
      original.name = user.name;
      original.admin = user.admin;
      original.superuser = user.superuser;
      setKey(original.email);
      const result = await doSave(user);
      console.log('doEdit', result);
      if (result && !result.error && keyChanged) {
        // delete old user
        await deleteUser(key);
      }
    }
  }

  async function doNew() {
    const user = getValues();
    user.email = user.email.toLowerCase();
    userData.push(user);
    setKey(user.email);
    doSave(user);
  }

  function handleClose(e, cancel = false) {
    console.log('handleClose', cancel);
    if (cancel) {
      // check if form is dirty - if so, confirm
    } else {
      // check if form is valid and save
      if (edit) {
        // update user
        doEdit();
      } else {
        // add user
        doNew();
      }
    }
    resetModals();
  }

  async function editUser(e, user) {
    // console.log('editUser', e);
    e.preventDefault();
    // setUser(user);
    setKey(user.email);
    setValue('email', user.email);
    setValue('name', user.name);
    setValue('admin', user.admin);
    setValue('superuser', user.superuser);
    setEdit(true);
    setShow(true);
    await fetchUser(user.email);
  }

  function newUser(e) {
    // console.log('newUser', e);
    e.preventDefault();
    setValue('email', '');
    setValue('name', '');
    setValue('admin', '');
    setEdit(false);
    setShow(true);
    setFocus('email');
  }

  const sorting = [
    {
      id: 'email', // Replace with the ID of the column you want to sort
      desc: true // Set to `true` for descending order, or `false` for ascending
    }
  ];

  const sortBy = React.useMemo(
    () => [
      {
        id: 'email',
        desc: false
      }
    ],
    []
  );

  const columns = [
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: ''
      },
      Cell: rowData => {
        return loadingSave && key === rowData.row.original.email ? (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        ) : (
          <a href="#" onClick={e => editUser(e, rowData.row.original)}>
            <FontAwesomeIcon icon={faEdit} className="" />
          </a>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      headerProps: { className: 'pe-1 text-900' }
    },
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-900' }
    },
    {
      accessor: 'admin',
      Header: 'Admin',
      headerProps: { className: 'text-900' },
      disableSortBy: true,
      Cell: rowData => {
        const { admin } = rowData.row.original;
        return admin && <FontAwesomeIcon icon={faCheck} className="mx-3" />;
      }
    }
  ];

  if (isSuperuser()) {
    columns.push({
      accessor: 'superuser',
      Header: 'Superuser',
      headerProps: { className: 'text-900' },
      disableSortBy: true,
      Cell: rowData => {
        const { superuser } = rowData.row.original;
        return superuser && <FontAwesomeIcon icon={faCheck} className="mx-4" />;
      }
    });
  }

  return (
    <>
      <Modal
        show={show}
        onHide={e => handleClose(e, true)}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {edit ? 'Edit User' : 'New User'}
          </Modal.Title>
          <Flex direction="row">
            {edit && loadingOne && (
              <>
                <span className="me-2 fs--1">Refreshing</span>
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="me-8"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </>
            )}
            {edit &&
              !confirmDelete &&
              !loadingOne &&
              key === watch('email') && (
                <a href="#" onClick={() => setConfirmDelete(true)}>
                  <FontAwesomeIcon icon={faTrashAlt} className="me-8" />{' '}
                </a>
              )}
            <CloseButton onClick={e => handleClose(e, true)} />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          {edit && confirmDelete && (
            <div>
              <p>
                Are you sure you want to delete the user <strong>{key}</strong>?
              </p>
            </div>
          )}
          {!confirmDelete && (
            <>
              <WizardInput
                type="text"
                label="Email"
                name="email"
                errors={errors}
                setValue={setValue}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  placeholder: 'name@example.com',
                  disabled: loadingOne,
                  ...register('email')
                }}
              />

              <WizardInput
                type="text"
                label="Name"
                name="name"
                errors={errors}
                setValue={setValue}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  placeholder: 'First Last',
                  disabled: loadingOne,
                  ...register('name')
                }}
              />

              <WizardInput
                type="checkbox"
                errors={errors}
                label="Admin user (can manage other users)"
                name="admin"
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  disabled: loadingOne,
                  ...register('admin')
                }}
              />

              {isSuperuser() && (
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Superuser"
                  name="superuser"
                  formGroupProps={{
                    className: 'mb-3'
                  }}
                  formControlProps={{
                    disabled: loadingOne,
                    ...register('superuser')
                  }}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button> */}
          {!confirmDelete && (
            <Button
              variant="primary"
              onClick={handleClose}
              disabled={loadingOne || !validEmail(watch('email'))}
            >
              {edit ? 'Save Changes' : 'Create User'}
            </Button>
          )}
          {confirmDelete && (
            <Button variant="danger" onClick={doDelete}>
              Delete User
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {loadingList && (
        <Card>
          <Card.Header></Card.Header>
          <Card.Body className="p-0">
            <div className="text-center mt-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            <div>
              <p className="text-center mt-5 mb-4">Loading users...</p>
            </div>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      )}
      {userData && !loadingList && (
        <AdvanceTableWrapper
          columns={columns}
          data={userData}
          // selection
          sortable
          sorting={sorting}
          sortBy={sortBy}
          pagination
          perPage={100}
          rowCount={userData.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-between-center flex-column flex-sm-row">
                <Col sm="auto" className="d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Users</h5>
                  {loadingDelete ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="mx-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      <span className="fs--1">Deleting {key}</span>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
                <Col sm="auto" className="">
                  <div id="orders-actions">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      onClick={newUser}
                    >
                      <span className="ms-1">New User</span>
                    </IconButton>
                  </div>
                </Col>
              </Row>
              {/* <UserTableHeader table /> */}
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={userData.length}
                table
                // rowInfo
                // navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default UserTable;
